import React from "react";
import logo from './img/logo.png'

export default function Navbar(){
    return(
        <div>
            
            <nav style={{position:"fixed", zIndex:"5", width:"100%"}} class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container-fluid">
                <a class="navbar-brand" href=""><img src={logo} style={{width:"50px"}}/></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav" style={{justifyContent:"flex-end"}}>
                <ul class="navbar-nav">
                    <li class="nav-item">
                    <a class="nav-link active" aria-current="page" href="#nosotros">Nosotros</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link active" href="#noticias">Noticias</a>
                    </li>
                    
                    <li class="nav-item">
                        <a style={{padding:"10px 20px", borderRadius:"10px", color:"white", backgroundColor:"rgb(223 133 133)"}}class="nav-link" href="#donar">DONAR AHORA</a>
                    </li> 
                    {/* <li class="nav-item">
                    <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li> */}
                </ul>
                </div>
            </div>
            </nav>
        </div>
    )
}