import './App.css';
import { Router, BrowserRouter as Switch, Route, Routes, BrowserRouter } from 'react-router-dom'

import Navbar from './components/Navbar.js'
import Footer from './components/Footer.js'
import Home from './components/Home.js'


function App() {
  return (
    <BrowserRouter>
        <Navbar/>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
        </Routes>
        <Footer/>
    </BrowserRouter>
  );
}
 //706946
export default App;
