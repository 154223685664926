import React from "react";
import tiktok from './img/tiktok.svg'


export default function Footer(){
    return(
        <div>
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 border-top" style={{backgroundColor:"#291e5a", padding:"0 25px"}}>
               
                <div>
                    <p style={{color:"white", marginBottom:"0", marginTop:"15px"}}>© Copyright 2024 www.untoquedeamor.org</p>
                    <p style={{color:"white", fontWeight:"100"}}><i class="bi bi-envelope-at-fill" style={{fontSize:"15px", paddingRight:"15px"}}></i>fundacion@untoquedeamor.org</p>
                    
                </div>
                <div className="d-flex" style={{alignItems:"center"}}>
                    <p style={{color:"white"}}>Síguenos</p>
                    <p><a href="https://www.facebook.com/profile.php?id=61554595563296"><i class="bi bi-facebook icon_size2" style={{padding:"0 25px"}}></i></a>
                    <a href="https://www.tiktok.com/@un_toque_de_amor_a.c"><i class="bi bi-tiktok icon_size2"></i></a></p>

                </div>

               
            </footer>


        </div>
    )
}