import React from "react";
import ban1 from './img/ban1.png'
import ban2 from './img/ban2.jpg'
import ban3 from './img/ban3.jpg'
import historia from './img/historia.png'
import logo from './img/logo.png'

import icon_amor from './img/icon_amor.png'
import icon_empatia from './img/icon_empatia.png'
import icon_generosidad from './img/icon_generosidad.png'
import icon_honestidad from './img/icon_honestidad.png'
import icon_servir from './img/icon_servir.png'
import icon_respeto from './img/icon_respeto.png'
import logomain from './img/logomain.jpg'



export default function Home(){
    return(
        <div style={{paddingTop:"75.63px"}}>
            <div  id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                    <img src={ban1} class="d-block w-100 beta" alt="..."/>
                    </div>
                    <div class="carousel-item">
                    <img src={ban2} class="d-block w-100 beta" alt="..." />
                    </div>
                    <div class="carousel-item">
                    <img src={ban3} class="d-block w-100 beta" alt="..."/>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            

            <div class="card text-center">
                <div class="card-header manosBan" style={{height:"50px"}}>
                    
                    </div>
                <div className="div1 animation_banner" id="nosotros">
                    <h2 className="animation_ban2" style={{color:"white"}}>¡QUÍENES SOMOS!</h2>

                </div>
                <div className=" " style={{padding:"35px 0 30px 0"}}>
                    <h2 className="animation_ban2">Nuestra Historia</h2>

                </div>
                <div class="card-footer text-muted"></div>
                <div class="card-body " style={{padding:"20px 40px"}}>
                 
                    <p class="card-text anim_texto" style={{textAlign:"justify"}}>La asociación civil “Un Toque De Amor A.C.” fue creada el 23 de noviembre del 2018 como una organización dedicada en la ejecución de proyectos que defienden y promocionan los derechos humanos, el mejoramiento de la calidad de vida y el desarrollo social a través de estrategias en educación, salud, 
                    alimentarias, culturales y deportivas para lograr una sociedad con armonía, justicia y libre de violencia. </p>

                    <p class="card-text anim_texto " style={{textAlign:"justify"}}>Fundada por la licenciada Mayra Erika Amador Cruz motivada e inspirada en su tercer hijo de nombre “Aquiles” que nació con Síndrome de Down, donde a lo largo de su vida se enfrentó al problema de desigualdad que aqueja a nuestras instituciones y sociedad, 
                    en la que se enfrentaron a un sinfín de obstáculos para la atención inmediata y oportuna de las necesidades y derechos de su hijo. </p>
                    <p class="card-text anim_texto" style={{textAlign:"justify"}}>Desafortunadamente, Aquiles falleció a los cuatro años de edad por un problema de salud y a partir de ahí la licenciada Mayra Erika Amador Cruz, decidió emprender una lucha por los derechos y reconocimiento de las preferencias y habilidades de los infantes 
                    en situación de vulnerabilidad y discriminación, fomentando su inclusión. </p>
                    <p class="card-text anim_texto" style={{textAlign:"justify"}}>Aquiles nos mostró que todo es posible, y nos enseñó a vivir con una sonrisa a pesar de las adversidades, para muchos fue un espíritu libre, un guerrero, un angelito; 
                    pero para su madre, el mejor ejemplo de un toque de amor. </p>
                    <p class="card-text anim_texto" style={{textAlign:"justify"}}>Es por ello que, a partir del 23 de noviembre del 2018, en la casa donde creció Aquiles, ubicada en el municipio de Atizapán de Zaragoza, se llevó a cabo la asamblea en la que se constituyó y creó la asociación civil “Un Toque De Amor” cuyo objeto de trabajo 
                    es el desarrollo social y la defensa y promoción de los derechos humanos, entre otros. </p>
                    <p class="card-text anim_texto" style={{textAlign:"justify"}}>A partir del 2018, se han impulsado proyectos en cumplimiento de nuestro objeto social, no solo en nuestro municipio si no en varias regiones de México, creciendo en nuestra operación, profesionalización e institucionalización para así poder brindar una mejor 
                    atención a cada uno de los beneficiarios de nuestras acciones. </p>
                    {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                    <img src={logomain} className="anim_texto"style={{width:"250px", paddingTop:"25px", marginBottom:"25px"}} />
                    
                    

                    
                </div>
                <div class="card-footer text-muted"></div>


                <div class="card-footer" style={{padding:"20px 40px"}}>
                    <h5 class="card-title animation_ban2">Misión</h5>
                    <p class="card-text anim_texto" style={{textAlign:"justify"}}>Promover una contexto social y cultural donde las niñas, niños y adolescentes conozcan 
                    y ejerzan sus derechos humanos; mejorando con esto su calidad de vida y una existencia digna que les permita alcanzar 
                    su máximo potencial mediante acciones educativas que fomenten vivir en una sociedad en armonía, con justicia y sin violencia.</p>
                    {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
                <div class="card-footer text-muted">
                    
                </div>

                <div class="card-footer" style={{padding:"20px 40px"}}>
                    <h5 class="card-title animation_ban2">Visión</h5>
                    <p class="card-text anim_texto" style={{textAlign:"justify"}}>Ser una asociación civil reconocida por las acciones a favor de la difusión y el ejercicio de los derechos humanos de niñas, 
                    niños y adolescentes; impactando favorablemente en el bienestar integral de esta población y en el estado de paz de la sociedad en general. </p>
                    {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
                <div class="">

                
                <div class="card-body" style={{padding:"20px 40px"}}>
                    <h5 class="card-title animation_ban2" style={{paddingBottom:"25px"}}>Nuestros valores</h5>
                    <p class="card-text anim_texto" style={{textAlign:"justify", paddingBottom:"25px"}}>Ser una asociación civil reconocida por las acciones a favor de la difusión y el ejercicio de los derechos humanos de niñas, 
                    niños y adolescentes; impactando favorablemente en el bienestar integral de esta población y en el estado de paz de la sociedad en general. </p>

                    <div className="div_valores">

                        <div className="iconSize anim_texto">
                            <img src={icon_amor} style={{width:"auto", height:"200px"}}/>
                            <p>Amor</p>
                        </div>
                        <div className="iconSize anim_texto">
                            <img src={icon_empatia} style={{width:"auto", height:"200px"}}/>
                            <p>Empatía</p>
                        </div>
                        <div className="iconSize anim_texto">
                            <img src={icon_generosidad} style={{width:"auto", height:"200px"}}/>
                            <p>Generosidad</p>
                        </div>
                        <div className="iconSize anim_texto">
                            <img src={icon_honestidad} style={{width:"auto", height:"200px"}}/>
                            <p>Honestidad</p>
                        </div>
                        <div className="iconSize anim_texto">
                            <img src={icon_servir} style={{width:"auto", height:"200px"}}/>
                            <p>Contribuir</p>
                        </div>
                        <div className="iconSize anim_texto">
                            <img src={icon_respeto} style={{width:"auto", height:"200px"}}/>
                            <p>Respeto</p>
                        </div>

                    </div>
                    {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                </div>
                    
                    
                </div>

                
            </div>
            
            <div   className="div2 animation_banner2">
                    <h2 id="noticias" className="animation_ban2" style={{color:"white"}}>NOTICIAS</h2>

            </div>
            <div class="card-header manosBan" style={{height:"50px"}}></div>

            <div className="div_valores">
                <div style={{padding:"25px 0"}}>
                    <iframe className="fb_pub anim_texto" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0QLfpSeEr4waANx5RJWAobAAQPtZ1wKQNCCZ47DgcwhNxb8zzAoVDD38QfPdoUaQMl%26id%3D61554595563296&show_text=true&width=500" width="500" height="827" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>

                
                <div style={{padding:"25px 0"}}>
                <iframe className="fb_pub anim_texto" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02wCdVY9KXtQfGw9z9jUgonjXYDFf2C1XujQtcHiqVAZmbLCf2sPUQT2gUVNi1h3Bvl%26id%3D61554595563296&show_text=true&width=500" width="500" height="618" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>
                
            
            
            </div>
            <div class="card-header manosBan" style={{height:"50px"}}>
                    
                    </div>
            <div className="div3 animation_banner">
                    <h2 id="donar" className="animation_ban2" style={{color:"white"}}>DONATIVOS</h2>

            </div>
            
            <div class="card text-center">
                <div class="card-header donar_ban ">
                    <h4 className="prl"style={{padding:"20px 0 0 0", fontWeight:"200"}}>Contamos con tu apoyo</h4>
                    <h2 className="prl anim_texto" style={{fontFamily:"fantasy"}}>REALIZA TU DONATIVO</h2>
                   
                    <p className="prl" style={{margin:"25px 0 0 0"}}>Banco: Default</p>
                    <p className="prl" style={{margin:"0"}}>No. DE CUENTA: ------</p>
                    <p className="prl" style={{margin:"0"}}>CLABE: -----------------</p>
                    <p className="prl" style={{fontWeight:"200", textAlign:"center", padding:"25px 25px"}}>Solicita tu recibo deducible de impuestos al correo “fundacion@untoquedeamor.org” con el asunto “Recibo deducible” junto con el comprobante de depósito.</p>
                    <h2 className="prl anim_texto" style={{fontFamily:"fantasy", marginBottom:"40PX"}}>Únete a esta misión y transforma vidas</h2>
                </div>
                <div class="card-body anim_texto">
                    <h5 class="card-title">CONTÁCTANOS</h5>
                    <a href="mailto:fundacion@untoquedeamor.org"><i class="bi bi-envelope-at-fill icon_size"></i></a>
                    <a href="https://www.facebook.com/profile.php?id=61554595563296"><i class="bi bi-facebook icon_size" style={{padding:"0 25px"}}></i></a>
                    <a href="https://www.tiktok.com/@un_toque_de_amor_a.c"><i class="bi bi-tiktok icon_size"></i></a>
                    
                </div>
                <div class="card-footer text-muted">  
                </div>
            </div>

        </div>
    )
}